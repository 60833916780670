var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{style:(_vm.backgroundImage
        ? ("background-image: url(" + _vm.backgroundImage + "); \n  padding: 40px 2.5vw;")
        : '')},[_c('div',{staticClass:"grid gap-4 text-center mt-10",class:[
        _vm.wFull ? 'grid-cols-1' : 'grid-cols-2',
        _vm.isTripleProduct ? 'md:grid-cols-3' : 'md:grid-cols-2 lg:grid-cols-4' ]},_vm._l((_vm.themeSaleProduct),function(product,index){return _c('div',{key:index},[_c('ProductCard',{attrs:{"image":product.media,"title":product.title,"price":product.price,"undiscounted-price":product.undiscountedPrice,"collection-price":product.collectionPrice
              ? product.collectionPrice[_vm.$i18n.localeProperties.currencyCode]
              : null,"collection-undiscounted-price":product.collectionUndiscountedPrice
              ? product.collectionUndiscountedPrice[
                  _vm.$i18n.localeProperties.currencyCode
                ]
              : null,"show-undiscounted-price":product.showUndiscountedPrice,"to":product.to,"badge":product.badge,"custom-badge":product.customBadge,"custom-badge-image":product.customBadgeImage,"custom-badge-text":product.customBadgeText,"custom-badge-class":product.customBadgeClass,"custom-badge-style":product.customBadgeStyle,"is-from":product.isFrom,"is-image-border":product.isImageBorder,"colored-product-title":_vm.coloredProductTitle,"is-triple-product":_vm.isTripleProduct,"grow-on-hover":"","lazy":"","is-full-height":""}})],1)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }